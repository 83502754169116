import { Injectable } from '@angular/core';
import { TrelloApiService } from './trello-api.service';

declare var TrelloPowerUp: any;  
declare var Trello: any;


@Injectable({
  providedIn: 'root'
})
export class TrelloService {

  constructor(private trelloPai: TrelloApiService) { }


  authorize(t, opts) {
    return t.popup({
      title: 'Autorização',
      items: [{
        text: 'Autorizo',
        callback: function (t, opts) {
          t.closePopup();
          let a = t.getRestApi();
          let return_url = window.location.href + 'auth-success';
          let b = a.authorize({ 'scope': 'read,write,account', 
                                'expiration': 'never',
                                'return_url': return_url,
                                'callback_method': function (token) {
                                  console.log('Token: '+token);
                                }});
                                
          
          b.then(function(t) {
              alert('Autorizado!');
          }).catch(TrelloPowerUp.restApiError.AuthDeniedError, function() {
            alert('Cancelado!');
          });
        }
      }, {
        text: 'Não Autorizo',
        callback: function (t, opts) {t.closePopup();}
      }]
    });    
  }

  getListData(t = null) {
    console.log("TrelloService::getListData()");
    if (t) return t.list('all');
    return t.iframe().list('all');
  }


  getCardData(t = null) {
    console.log("TrelloService::getCardData()");
    if (t) return t.card('all');
    return t.iframe().card('all');
  }

  private static formatTimeDiff(date, now) {
    let timeDiff = Math.floor((now - date) / 1000);


    // Diferença em segundos
    let textValue: string;
    if (timeDiff < 60) {
      textValue = String(Math.floor(timeDiff)) + 's';
    } else if (timeDiff < 3600) {
      textValue = String(Math.floor(timeDiff / 60)) + 'm';
    } else if (timeDiff < 86400) {
      textValue = String(Math.floor(timeDiff / 3600)) + 'h';
    } else {
      textValue = String(Math.floor(timeDiff / 86400)) + 'd';
    }
    console.log(`TrelloService::formatTimeDiff() => ${textValue}`);
    return textValue;

  }

  getBadgeData(trello, opts) {
    let t = trello;
    const _this = this;
    console.log("TrelloService::getBadgeData()");
    return [{
        dynamic: function() {return Promise.all([
        _this.getCardData(t),
        _this.getListData(t)
    ]).then(value => {
      const card = value[0];
      const list = value[1];

      if ((!card) || (!list)) {
          return;
      }

      const regex = /\d{4,5} - /gm;
      let m = regex.exec(card.name);

      if (m === null) {
          return {};
      }

      return t.get(card.id, 'shared', 'tpano-age-lastupdate', null)
      .then(lastRefresh => {
        if (lastRefresh === card.dateLastActivity)
          return t.get(card.id, 'shared', 'tpano-age-inlist', null)
                 .then (oldDate => {
                    console.log (`lendo da memória em ${card.id} (${card.shortLink})`);
                    return {
                      text: TrelloService.formatTimeDiff(oldDate,  Date.now()),
                      refresh: 12
                    };                   
                 }) 
        console.log (`lendo da API em ${card.id} (${card.shortLink})`);
        return Trello.get('cards/'+card.id+'/actions', {filter: 'updateCard,createCard'}).then(actions => {
          if (!actions.length) {
            return {};
          }
          let now = Date.now();
          let oldDate = now;
          for (let action of actions) {
            if ((action.type === 'createCard') ||
                ((action.data.hasOwnProperty('old')) &&
                 (action.data.old.hasOwnProperty('idList')))) {
                oldDate = new Date(action.date).getTime();
                t.set(card.id, 'shared', 'tpano-age-inlist', oldDate);
                t.set(card.id, 'shared', 'tpano-age-lastupdate', card.dateLastActivity);
                break;
            }
          }

          return {
            text: TrelloService.formatTimeDiff(oldDate, now),
            refresh: 15
          };
        });
      });
    })
  }}];
  }  
}
