import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-success',
  templateUrl: './auth-success.component.html',
  styleUrls: ['./auth-success.component.css']
})
export class AuthSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
