import { Component, OnInit } from '@angular/core';
import { TrelloBootstrapService } from '../services/trello-bootstrap.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private trelloBootstrapService: TrelloBootstrapService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    let token = null;
    if (this.route.snapshot.fragment)
      token = this.route.snapshot.fragment.substring(6);
    if (token) {
      this.trelloBootstrapService.setToken(token);
    } else {
      this.trelloBootstrapService.initialize();
    }
  }

}
