import { Injectable } from '@angular/core';
import * as internal from 'assert';
import { TrelloService } from './trello.service';

const CONFIGCAT_ICON = './assets/cat_red.svg';

declare var TrelloPowerUp: any;
declare var Trello: any;

@Injectable({
  providedIn: 'root'
})
export class TrelloBootstrapService {

  private c: number;

  constructor(private trelloService: TrelloService) { }

  setToken(token) {
    // if (window.opener) {
    //   window.opener.Trello.authorize(token);
    // }    
    
    var t = TrelloPowerUp.iframe({
      appKey: 'e3c024c20ea3aee92301be797aeac388',
      appName: 'Power-up de uso privativo da Tinta no Pano'
    });

    t.storeSecret('token', token);

    setTimeout(() => {
      window.close();
    }, 600);
        
  }

  initialize() {
    console.log('TrelloBootstrapService::initialize()');
    this.c = 0;
    TrelloPowerUp.initialize({
      //'card-back-section': this.getCardBackSection,
      //'card-buttons': this.getCardButtons,
      'board-buttons': this.getBoardButtons,
      //'authorization-status': this.getAuthorizationStatus,
      //'show-authorization': this.showAuthorization,
      //'on-disable': this.disable,
      'card-badges': this.getBadges
    }, {
      'appKey': 'e3c024c20ea3aee92301be797aeac388',
      'appName': 'Power-up de uso privativo da Tinta no Pano'
    });
  }


  private getBadges = (t, opts) => {
    return t.getRestApi()
            .isAuthorized()
            .then(authorized => { 
              if (!authorized) 
                return; 
              else
                return this.trelloService.getBadgeData(t, opts);
            });    
  }  

  private getBoardButtons = (t, opts) => {
    console.log('TrelloBootstrapService::getBoardButtons()');
    let a = t.getRestApi();
    return t.getRestApi()
            .isAuthorized()
            .then(authorized => { 
              console.log(`authorized=${authorized}`);
              if (!authorized) 
                return[{
                  // usually you will provide a callback function to be run on button click
                  // we recommend that you use a popup on click generally
                  icon: CONFIGCAT_ICON,
                  text: 'Autorize-me',
                  callback: this.trelloService.authorize
              }]; else {
                t.getRestApi()
                 .getToken()
                 .then(token => {
                    Trello.setToken(token);
                 }) 
                return [];
              }
            });
  }

  getCardButtons = (t, options) => {
    console.log('TrelloBootstrapService::getCardButtons()');
    return;

    let descr = t.card("id","desc","shortLink").then(v => {
      console.log(v[0]);
    });
    return [{
      // usually you will provide a callback function to be run on button click
      // we recommend that you use a popup on click generally
      icon: CONFIGCAT_ICON,
      text: 'Olá Cartão!!',
    }];
  }

}
